var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.wasOnboardingChecklistDismissed && _vm.featureToDisplay && _vm.isPlanPriceFetchedIfNecessary)?_c('div',[_c('div',{staticClass:"flex flex-col space-y-4 text-base mb-6 border border-divider py-4 px-6 rounded-lg"},[(_vm.shouldFeatureReminderBeDisplayed('followHypefuryOnTwitter'))?_c('div',{staticClass:"flex items-center space-x-2"},[(_vm.isFollowingHypefuryTwitterAccount)?_c('span',[_vm._v("Following @Hypefury account, please wait...")]):_vm._e(),(!_vm.isFollowingHypefuryTwitterAccount)?_c('span',[_vm._v(" How about you follow Hypefury on Twitter? ")]):_vm._e(),_c('div',{staticClass:"flex space-x-2 items-center"},[(!_vm.isFollowingHypefuryTwitterAccount)?_c('span',{staticClass:"cursor-pointer underline text-accent",on:{"click":_vm.followHypefuryTwitterAccount}},[_vm._v(" Follow ")]):_vm._e(),(!_vm.isFollowingHypefuryTwitterAccount)?_c('span',{staticClass:"cursor-pointer underline text-muted text-xs",on:{"click":() => {
              _vm.hideFeature('followHypefuryOnTwitter');
              _vm.$eventStore.featureReminder.clickIgnore('followHypefuryOnTwitter');
            }}},[_vm._v("Ignore")]):_vm._e()])]):_vm._e(),(_vm.shouldFeatureReminderBeDisplayed('gumroadSalesInactivity'))?_c('div',{staticClass:"flex items-center space-x-2"},[_c('span',[_vm._v("Hey! It's been a while since you ran a Gumroad sale!")]),_c('div',{staticClass:"flex space-x-2 items-center"},[_c('router-link',{attrs:{"to":"/gumroad-sales"},nativeOn:{"click":function($event){return _vm.$eventStore.featureReminder.gumroadSalesInactivityVisitGumroadSalesPage()}}},[_vm._v(" Let's make some cash!! ")]),_c('span',{staticClass:"cursor-pointer underline text-muted text-xs",on:{"click":() => {
              _vm.hideFeature('gumroadSalesInactivity');
              _vm.$eventStore.featureReminder.clickIgnore('gumroadSalesInactivity');
            }}},[_vm._v("Ignore")])],1)]):_vm._e(),(_vm.shouldFeatureReminderBeDisplayed('gumroadNotConnected'))?_c('div',{staticClass:"flex items-center space-x-2"},[_c('span',[_vm._v(" Selling on Gumroad? Connect your seller account to be able to run automated sales. Hypefury will also display your Gumroad revenue in the sidebar. ")]),_c('div',{staticClass:"flex space-x-2 items-center"},[_c('router-link',{attrs:{"to":"/settings#gumroad"},nativeOn:{"click":function($event){return _vm.$eventStore.featureReminder.gumroadNotConnectedVisitSettingsPage()}}},[_vm._v(" Connect ")]),_c('span',{staticClass:"text-muted text-xs"},[_vm._v("–")]),_c('span',{staticClass:"cursor-pointer underline text-muted text-xs",on:{"click":() => {
              _vm.hideFeature('gumroadNotConnected');
              _vm.$eventStore.featureReminder.clickIgnore('gumroadNotConnected');
            }}},[_vm._v("Ignore")])],1)]):_vm._e(),(_vm.shouldFeatureReminderBeDisplayed('linkedInInactivity'))?_c('div',{staticClass:"flex items-center space-x-2"},[_vm._m(0),_c('div',{staticClass:"flex space-x-2 items-center"},[_c('span',{staticClass:"cursor-pointer underline text-muted text-xs",on:{"click":() => {
              _vm.hideFeature('linkedInInactivity');
              _vm.$eventStore.featureReminder.clickIgnore('linkedInInactivity');
            }}},[_vm._v("Got it!")])])]):_vm._e(),(_vm.shouldFeatureReminderBeDisplayed('linkedInNotConnected'))?_c('div',{staticClass:"flex items-center space-x-2"},[_c('span',[_vm._v(" Did you know that you could crosspost your tweets to LinkedIn? ")]),_c('div',{staticClass:"flex space-x-2 items-center"},[_c('router-link',{attrs:{"to":"/settings#linkedin"},nativeOn:{"click":function($event){return _vm.$eventStore.featureReminder.linkedInNotConnectedVisitSettingsPage()}}},[_vm._v(" Connect your LinkedIn account ")]),_c('span',{staticClass:"text-muted text-xs"},[_vm._v("–")]),_c('span',{staticClass:"cursor-pointer underline text-muted text-xs",on:{"click":() => {
              _vm.hideFeature('linkedInNotConnected');
              _vm.$eventStore.featureReminder.clickIgnore('linkedInNotConnected');
            }}},[_vm._v("Ignore")])],1)]):_vm._e(),(_vm.shouldFeatureReminderBeDisplayed('instagramInactivity'))?_c('div',{staticClass:"flex items-center space-x-2"},[_vm._m(1),_c('div',{staticClass:"flex space-x-2 items-center"},[_c('span',{staticClass:"cursor-pointer underline text-muted text-xs",on:{"click":() => {
              _vm.hideFeature('instagramInactivity');
              _vm.$eventStore.featureReminder.clickIgnore('instagramInactivity');
            }}},[_vm._v(" Will do! ")])])]):_vm._e(),(_vm.shouldFeatureReminderBeDisplayed('instagramNotConnected'))?_c('div',{staticClass:"flex items-center space-x-2"},[_c('span',[_vm._v(" Are you on IG? Connect your page to Hypefury so it can post your tweets on IG! (only when you ask it to) ")]),_c('div',{staticClass:"flex space-x-2 items-center"},[_c('router-link',{attrs:{"to":"/settings#instagram"},nativeOn:{"click":function($event){return _vm.$eventStore.featureReminder.instagramNotConnectedVisitSettingsPage()}}},[_vm._v(" Connect your account ")]),_c('span',{staticClass:"text-muted text-xs"},[_vm._v("–")]),_c('span',{staticClass:"cursor-pointer underline text-muted text-xs",on:{"click":() => {
              _vm.hideFeature('instagramNotConnected');
              _vm.$eventStore.featureReminder.clickIgnore('instagramNotConnected');
            }}},[_vm._v("Ignore")])],1)]):_vm._e(),(_vm.shouldFeatureReminderBeDisplayed('facebookInactivity'))?_c('div',{staticClass:"flex items-center space-x-2"},[_vm._m(2),_c('div',{staticClass:"space-x-2"},[_c('span',{staticClass:"cursor-pointer underline text-muted text-xs",on:{"click":() => {
              _vm.hideFeature('facebookInactivity');
              _vm.$eventStore.featureReminder.clickIgnore('facebookInactivity');
            }}},[_vm._v("Got it!")])])]):_vm._e(),(_vm.shouldFeatureReminderBeDisplayed('facebookNotConnected'))?_c('div',{staticClass:"flex items-center space-x-2"},[_c('span',[_vm._v(" Got a Facebook page or a group? Connect it so you can share your tweet on Facebook too! ")]),_c('div',{staticClass:"flex space-x-2 items-center"},[_c('router-link',{attrs:{"to":"/settings#facebook"},nativeOn:{"click":function($event){return _vm.$eventStore.featureReminder.facebookNotConnectedVisitSettingsPage()}}},[_vm._v(" Connect your account ")]),_c('span',{staticClass:"text-muted text-xs"},[_vm._v("–")]),_c('span',{staticClass:"cursor-pointer underline text-muted text-xs",on:{"click":() => {
              _vm.hideFeature('facebookNotConnected');
              _vm.$eventStore.featureReminder.clickIgnore('facebookNotConnected');
            }}},[_vm._v("Ignore")])],1)]):_vm._e(),(_vm.shouldFeatureReminderBeDisplayed('evergreenRetweetsNotEnabled'))?_c('div',{staticClass:"flex items-center space-x-2"},[_vm._m(3),_c('div',{staticClass:"flex space-x-2 items-center"},[_c('router-link',{attrs:{"to":{path:`/queue#schedule`}}},[_vm._v("Let's go!")]),_c('span',{staticClass:"text-muted text-xs"},[_vm._v("–")]),_c('span',{staticClass:"cursor-pointer underline text-muted text-xs",on:{"click":() => {
              _vm.hideFeature('evergreenRetweetsNotEnabled');
              _vm.$eventStore.featureReminder.clickIgnore('evergreenRetweetsNotEnabled');
            }}},[_vm._v(" No ")])],1)]):_vm._e(),(_vm.shouldFeatureReminderBeDisplayed('autoplugNotEnabled'))?_c('div',{staticClass:"flex items-center space-x-2"},[_c('router-link',{attrs:{"to":"/powerups#autoplug"}},[_vm._v(" You're currently not using Hypefury's most powerful automation. Stop missing-out on free engagement and let Hypefury plug your offer under your popular tweets! ")]),_c('div',{staticClass:"flex space-x-2 items-center"},[_c('span',{staticClass:"text-muted text-xs"},[_vm._v("–")]),_c('span',{staticClass:"cursor-pointer underline text-muted text-xs",on:{"click":() => {
              _vm.hideFeature('autoplugNotEnabled');
              _vm.$eventStore.featureReminder.clickIgnore('autoplugNotEnabled');
            }}},[_vm._v("Ignore")])])],1):_vm._e(),(_vm.shouldFeatureReminderBeDisplayed('autoRetweetsNotEnabled'))?_c('div',{staticClass:"flex items-center space-x-2"},[_c('router-link',{attrs:{"to":"/powerups#auto-retweeter"}},[_vm._v(" Give your tweets a boost by having Hypefury retweet them a few hours after they're posted! ")]),_c('div',{staticClass:"flex space-x-2 items-center"},[_c('span',{staticClass:"text-muted text-xs"},[_vm._v("–")]),_c('span',{staticClass:"cursor-pointer underline text-muted text-xs",on:{"click":() => {
              _vm.hideFeature('autoRetweetsNotEnabled');
              _vm.$eventStore.featureReminder.clickIgnore('autoRetweetsNotEnabled');
            }}},[_vm._v("Ignore")])])],1):_vm._e(),(_vm.shouldFeatureReminderBeDisplayed('addAccountManager'))?_c('div',{staticClass:"flex items-center space-x-2"},[_c('span',[_vm._v(" Did you know you could invite your VA or ghostwriter to write tweets for you? ")]),_c('div',{staticClass:"flex space-x-2 items-center"},[_c('router-link',{attrs:{"to":"/settings#account-managers"},nativeOn:{"click":function($event){return _vm.$eventStore.featureReminder.addAccountManagerVisitSettingsPage()}}},[_vm._v(" I'd like to ")]),_c('span',{staticClass:"text-muted text-xs"},[_vm._v("–")]),_c('span',{staticClass:"cursor-pointer underline text-muted text-xs",on:{"click":() => {
              _vm.hideFeature('addAccountManager');
              _vm.$eventStore.featureReminder.clickIgnore('addAccountManager');
            }}},[_vm._v("Ignore")])],1)]):_vm._e(),(_vm.shouldFeatureReminderBeDisplayed('pinTabToBrowser'))?_c('div',{staticClass:"flex items-center space-x-2"},[_c('a',{staticClass:"cursor-pointer",on:{"click":() => {
            _vm.showPinTabModal = true;
            _vm.$eventStore.featureReminder.pinTabToBrowserClickDisplayTutorial();
          }}},[_vm._v(" Tip: pin this tab to your browser so you can quickly access Hypefury when you need it ")]),_c('span',{staticClass:"text-muted text-xs"},[_vm._v("–")]),_c('div',{staticClass:"flex space-x-2 items-center"},[_c('span',{staticClass:"cursor-pointer underline text-muted text-xs",on:{"click":() => {
              _vm.hideFeature('pinTabToBrowser');
              _vm.$eventStore.featureReminder.clickIgnore('pinTabToBrowser');
            }}},[_vm._v("Ignore")])]),_c('modal',{attrs:{"show":_vm.showPinTabModal,"body-classes":"p-0","modal-classes":"modal-dialog-centered modal-lg image-modal"},on:{"close":function($event){_vm.hideFeature('pinTabToBrowser');
          _vm.showPinTabModal = false;}}},[_c('div',{staticClass:"modal-title",attrs:{"slot":"header"},slot:"header"},[_vm._v(" How to pin a tab to your browser ")]),_c('div',[_c('img',{staticClass:"rounded-md mx-auto",attrs:{"src":"/img/pintab.gif"}})])])],1):_vm._e(),(_vm.shouldFeatureReminderBeDisplayed('enableNotifications'))?_c('div',{staticClass:"flex items-center space-x-2"},[_c('span',{staticClass:"cursor-pointer",on:{"click":_vm.enableNotificationsThenHideAlert}},[_vm._v(" Allow Hypefury to remind you of when your queue is empty ")]),_c('div',{staticClass:"flex space-x-2 items-center"},[_c('span',{staticClass:"text-muted text-xs"},[_vm._v("–")]),_c('span',{staticClass:"cursor-pointer underline text-muted text-xs",on:{"click":() => {
              _vm.hideFeature('enableNotifications');
              _vm.$eventStore.featureReminder.clickIgnore('enableNotifications');
            }}},[_vm._v("Ignore")])])]):_vm._e(),(_vm.shouldFeatureReminderBeDisplayed('promoteHypefury'))?_c('div',{staticClass:"flex items-center space-x-2"},[_c('span',[_vm._v(" Give Hypefury some love: do you want to automatically schedule a weekly recurrent post to promote Hypefury through your affiliate link? ")]),_c('div',{staticClass:"flex space-x-2 items-center"},[_c('span',{staticClass:"cursor-pointer underline text-muted text-xs",on:{"click":_vm.createHypefuryAffiliateCategoryAndScheduleIt}},[_vm._v(" Yes! ")]),_c('span',{staticClass:"text-muted text-xs"},[_vm._v("–")]),_c('span',{staticClass:"cursor-pointer underline text-muted text-xs",on:{"click":function($event){return _vm.hideFeature('promoteHypefury')}}},[_vm._v("No, thanks")])])]):_vm._e(),(_vm.shouldFeatureReminderBeDisplayed('upgradeToYearly'))?_c('div',{staticClass:"flex items-center space-x-2"},[_c('span',[_vm._v(" You've unlocked "+_vm._s(_vm.parentUserProfile.isStripeCustomer ? '$' : '')+_vm._s(_vm.plan.gross * 2)+" "+_vm._s(!_vm.parentUserProfile.isStripeCustomer ? _vm.plan.currency : '')+" discount. ")]),_c('div',{staticClass:"flex space-x-2 items-center"},[_c('a',{staticClass:"cursor-pointer",on:{"click":_vm.upgradeToYearlyThenDismissReminder}},[_vm._v(" Get your yearly plan now! ")]),_c('span',{staticClass:"text-muted text-xs"},[_vm._v("–")]),_c('span',{staticClass:"cursor-pointer underline text-muted text-xs",on:{"click":() => {
              _vm.hideFeature('upgradeToYearly');
              _vm.$eventStore.featureReminder.clickIgnore('upgradeToYearly');
            }}},[_vm._v("Lose the discount")])])]):_vm._e(),(_vm.shouldFeatureReminderBeDisplayed('highAutoplugPostingPercent'))?_c('div',{staticClass:"flex items-center space-x-2"},[_c('span',[_vm._v("You are currently autoplugging more than half of your tweets. Would you consider lowering the autoplug frequency?")]),_c('router-link',{attrs:{"to":"/powerups#autoplug"}},[_vm._v(" Yes ")]),_c('span',{staticClass:"text-muted text-xs"},[_vm._v("–")]),_c('div',{staticClass:"flex space-x-2 items-center"},[_c('span',{staticClass:"cursor-pointer underline text-muted text-xs",on:{"click":() => {
              _vm.hideFeature('highAutoplugPostingPercent');
              _vm.$eventStore.featureReminder.clickIgnore('highAutoplugPostingPercent');
            }}},[_vm._v("Ignore")])])],1):_vm._e()])]):_vm._e()
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('span',[_vm._v(" You haven't crossposted your tweets to LinkedIn for a while. Enable "),_c('em',[_vm._v("sharing to LinkedIn")]),_vm._v(" the next time you schedule something! ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',[_vm._v(" You haven't crossposted your tweets to IG for a while. Enable "),_c('em',[_vm._v("sharing to IG")]),_vm._v(" the next time you schedule something! ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',[_vm._v(" You haven't crossposted your tweets to Facebook for a while. Enable "),_c('em',[_vm._v("sharing to Facebook")]),_vm._v(" the next time you schedule something! ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',[_vm._v(" How about making empty slots act as evergreen ones?"),_c('br'),_vm._v(" That would keep your account active when you're away while sharing value with your new followers. ")])
}]

export { render, staticRenderFns }